@import 'variables.scss';
@import '~quasar/src/css/index.sass';
@import 'quasar.scss';

:root {
  // rbt colors
  --main-bg-color: #{$main-bg-color};
  --main-text-color: #{$main-text-color};

  // additional quasar colors
  --q-color-grey: #{$grey};
  --q-color-grey-1: #{$grey-1};
  --q-color-grey-2: #{$grey-2};
  --q-color-grey-3: #{$grey-3};
  --q-color-grey-4: #{$grey-4};
  --q-color-grey-5: #{$grey-5};
  --q-color-grey-6: #{$grey-6};
  --q-color-grey-7: #{$grey-7};
  --q-color-grey-8: #{$grey-8};
  --q-color-grey-9: #{$grey-9};
  --q-color-grey-10: #{$grey-10};
  --q-color-grey-11: #{$grey-11};
  --q-color-grey-12: #{$grey-12};
  --q-color-grey-13: #{$grey-13};
  --q-color-grey-14: #{$grey-14};
}

body {
  font-family: 'Inter', sans-serif;
  background-color: var(--main-bg-color, $main-bg-color) !important;
  color: var(--main-text-color, $main-text-color) !important;
}

.bg-main-bg-color {
  background-color: var(--main-bg-color, $main-bg-color) !important;
}

.bg-main {
  background-color: var(--main-text-color, $main-text-color) !important;
}

.text-main {
  color: var(--main-text-color, $main-text-color) !important;
}
