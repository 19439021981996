.q-table__card,
.q-item__label--caption,
.q-field__control,
.q-field__native,
.q-field__prefix,
.q-field__suffix,
.q-field__input,
.q-field__bottom,
.q-item__label--header,
.q-field__marginal {
  color: inherit;
}

.q-item__label--header {
  font-weight: 700;
}
